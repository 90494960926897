@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.clients-page {
  flex: 1 1;
  padding: 20px 0;

  .clients-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

@media all and (max-width: $ipad) {
  .clients-page {
    padding: 20px 0;

    .clients-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
