/* brand colors */
$brand0: #c53037;
$brand1: #51459b;
$brand2: #5296f3;
$brand3: #0bb260;
$brand4: #fe9c00;

/* main colors */
$red: #d32f2f;
$pink: #c2185b;
$purple: #7b1fa2;
$indigo: #303f9f;
$blue: #1976d2;
$sky: #0288d1;
$cyan: #0097a7;
$teal: #00796b;
$green: #388e3c;
$lime: #689f38;
$olive: #afb42b;
$yellow: #fbc02d;
$amber: #ffa000;
$orange: #f57c00;
$brick: #e64a19;
$brown: #5d4037;
$black: #101010;
$dark: #212121;
$gray: #424242;
$gray1: #696969;
$gray2: #909090;
$gray3: #aeaeae;
$gray4: #cbcbcb;
$gray5: #dfdfdf;
$gray6: #f3f3f3;
$light: #fcfcfc;
$white: #fff;

$lightShades: $white;
$lightAccent: $gray;
$mainBrand: $brand4;
$darkAccent: $brand1;
$darkShades: $dark;

/* COLORS CONSTANTS */
/* background colors */
$ui-bg-accent: $darkAccent;
$ui-bg-brand: $mainBrand;
$ui-bg-dark: $dark;
$ui-bg-gray: $gray;
$ui-bg-gray6: $gray6;
$ui-bg-light: $light;
$ui-bg-white: $white;

/* button colors */
$ui-btn-accent: $darkAccent;
$ui-btn-brand: $mainBrand;
$ui-btn-dark: $dark;
$ui-btn-white: $white;

/* gradient colors */
$ui-grad-start: $dark;
$ui-grad-end: $dark;

/* icon colors */
$ui-icon-accent: $darkAccent;
$ui-icon-brand: $mainBrand;
$ui-icon-dark: $dark;
$ui-icon-gray: $gray;
$ui-icon-light: $light;
$ui-icon-menu: $gray;
$ui-icon-white: $white;

/* line colors */
$ui-line-accent: $darkAccent;
$ui-line-brand: $mainBrand;
$ui-line-dark: $dark;
$ui-line-gray: $gray4;
$ui-line-header: $gray;
$ui-line-table: $light;

/* shadow colors */
$ui-shadow: $gray1;
$ui-shadow-table: $gray5;

/* text colors */
$ui-text-accent: $darkAccent;
$ui-text-brand: $mainBrand;
$ui-text-dark: $dark;
$ui-text-gray: $gray;
$ui-text-dark-gray: $gray;
$ui-text-light: $light;
$ui-text-menu: $gray;
$ui-text-search: $gray1;
$ui-text-white: $white;

/* transparent colors */
