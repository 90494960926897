@import "./colors.scss";
@import "./media.scss";

.app {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100vh;
  background-color: $ui-bg-light;

  .app-container {
    flex-grow: 1;
    flex-shrink: 1;
    grid-template-columns: 60px 1fr;
    overflow: hidden;

    .app-content {
      overflow: hidden;
      overflow-y: auto;
    }

    &.full-container {
      grid-template-columns: 1fr;
    }
  }
}

@media all and (max-width: $macbook) {
  .app {
  }
}

@media all and (max-width: $desktop) {
  .app {
  }
}

@media all and (max-width: $ipad) {
  .app {
    .app-container {
      grid-template-columns: 1fr;
    }
  }
}

@media all and (max-width: $mobile) {
  .app {
    .app-container {
      padding-bottom: 0;
    }
  }
}

@media all and (max-width: $small) {
  .app {
    .app-container {

    }
  }
}
