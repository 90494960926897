@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.login-page {
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  .form {
    flex: 1 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;

    .form-inner {
      padding: 0 16px;
      width: 408px;
    }

    .login-title-wrap {
      margin-bottom: 20px;
    }

    .login-input-wrap {
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .login-button-wrap {
      width: 100%;
      padding-top: 24px;
      padding-bottom: 14px;
    }

    .reg-button-wrap {
      width: 100%;
      padding-bottom: 14px;
    }
  }
}

@media (max-width: $ipad) {
  .login-page {
    flex-direction: column-reverse;

    .form {
      flex: 1 1;
      width: 100vw;
      height: auto;

      .form-inner {
        align-items: center;
      }

      .login-title-wrap {
        align-items: center;
      }
    }
  }
}

@media (max-width: $mobile) {
  .login-page {
    .form {
      .form-inner {
        width: 100%;
        padding: 0 8px;
      }
    }
  }
}
