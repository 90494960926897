@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
    padding: 0 20px;
  }

  .home-page-empty {
    flex: 1 1;
    align-items: center;
    justify-content: center;

    .home-page-empty-icon {
      width: 64px;
      height: 64px;
      margin-bottom: 20px;
      fill: $ui-icon-accent;
    }

    h3 {
      font-size: 18px;
      margin-bottom: 8px;
    }

    .home-page-empty-button {
      min-width: 200px;
      margin-top: 24px;
    }
  }
}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 12px 0;

    .home-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
