@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.header-wrap {
  position: relative;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $ui-bg-dark;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &.bg-login {
    background-image: url(../../assets/images/login/ZZkw00YCY1E-unsplash.jpg);
  }

  &.bg-profile {
    background-image: url(../../assets/images/login/Efqto2rHSbk-unsplash.jpg);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($ui-bg-dark, 0.45);
  }

  .header-bg {
    position: absolute;
    left: -1px;
    bottom: -1px;
    z-index: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    align-items: flex-start;
  }

  .header-inner {
    position: relative;
    z-index: 1;
    max-width: 490px;
    padding: 15% 20px;

    &>p {
      padding: 0 16px;
    }
  }
}

@media (max-width: $ipad) {
  .header-wrap {
    flex: 0 0;
    width: 100vw;
    height: auto;
    text-align: center;

    .header-bg {
      align-items: center;
      justify-content: flex-end;
    }

    .header-inner {
      padding-top: 25%;
      width: auto;
      transition: all 0.35s ease-out 0;

      &.keyboard-active {
        padding-top: 15%;
        padding-bottom: 5%;
        transition: all 0.35s ease-out 0;

        &>p {
          height: 0;
          opacity: 0;
          transition: all 0.35s ease-out 0;
        }
      }

      h2 {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .header-wrap {
    .header-inner {
      padding-top: 35%;
    }
  }
}