@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.button-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
}

.button {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border: 1px solid $ui-btn-dark;
  background-color: $ui-btn-dark;

  span {
    color: $ui-text-white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.small {
    width: auto;
    height: 32px;
    padding: 0 16px;
    border-radius: 16px;

    span {
      text-transform: none;
    }
  }
}

.button-brand {
  border-color: $ui-btn-brand;
  background-color: $ui-btn-brand;
}
.button-accent {
  border-color: $ui-btn-accent;
  background-color: $ui-btn-accent;
}
.button-gray {
  border-color: $gray4;
  background-color: $gray4;
}

.button-red {
  border-color: $red;
  background-color: $red;
}
.button-pink {
  border-color: $pink;
  background-color: $pink;
}
.button-purple {
  border-color: $purple;
  background-color: $purple;
}
.button-indigo {
  border-color: $indigo;
  background-color: $indigo;
}
.button-blue {
  border-color: $blue;
  background-color: $blue;
}
.button-sky {
  border-color: $sky;
  background-color: $sky;
}
.button-cyan {
  border-color: $cyan;
  background-color: $cyan;
}
.button-teal {
  border-color: $teal;
  background-color: $teal;
}
.button-green {
  border-color: $green;
  background-color: $green;
}
.button-lime {
  border-color: $lime;
  background-color: $lime;
}
.button-olive {
  border-color: $olive;
  background-color: $olive;
}
.button-yellow {
  border-color: $yellow;
  background-color: $yellow;
}
.button-amber {
  border-color: $amber;
  background-color: $amber;
}
.button-orange {
  border-color: $orange;
  background-color: $orange;
}
.button-brick {
  border-color: $brick;
  background-color: $brick;
}
.button-brown {
  border-color: $brown;
  background-color: $brown;
}
.button-dark {
  border-color: $dark;
  background-color: $dark;
}
.button-white {
  border: 1px solid $ui-line-gray;
  background-color: $ui-btn-white;

  span {
    color: $ui-text-dark;
  }
}
