@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.table-small {
  width: 100%;

  .table-wrap {
    width: 100%;
    margin: 16px 0;
    border: 1px solid $ui-line-gray;
    border-radius: 4px;
    background-color: $ui-bg-white;
    overflow: hidden ;

    .table-scroll {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
    }
  }

  .table {
    width: 100%;
    border-radius: 8px;
    border-collapse: collapse;
    border-spacing: 0;
  }
}

@media all and (max-width: $ipad) {
  .table-small {
    .table-wrap {
      margin: 0;
      box-shadow: none;
    }
  }
}
