@import "../../styles/colors.scss";

.textarea-wysiwyg {
  position: relative;
  width: 100%;
  min-height: 300px;
  border: 1px solid $ui-line-dark;
  border-radius: 0.5rem;

  .textarea-wysiwyg-wrapper {
    .textarea-wysiwyg-toolbar {
      flex-direction: row;
      justify-content: space-between;
      border-radius: 0.5rem;
      border-width: 0;
      background-color: $ui-bg-light;

      & > div {
        flex-direction: row;
      }

      .rdw-dropdown-wrapper {
        position: relative;

        .rdw-dropdown-optionwrapper {
          position: absolute;
        }
      }

      .rdw-colorpicker-modal-options {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.5rem;

        .rdw-colorpicker-option {
          position: relative;
          width: 100%;
          height: auto;
          padding-top: 100%;
          margin: 0;
          box-shadow: none;

          .rdw-colorpicker-cube {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid $ui-line-gray;
          }
        }
      }
    }

    .textarea-wysiwyg-editor {
      padding: 0 1.5rem;
    }
  }
}
