@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.storages-page {
  flex: 1 1;
  padding: 20px 0;

  .storages-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

.storage-add-images-draggable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 8px;
}

#page.product-add-page {
  margin: 20px 0;
  background-color: $ui-bg-white;

  .product-add-tabs {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px;
    
    .product-add-tab {
      margin-right: 20px;
    }
  }

  .product-add-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;

    .product-add-about {
      .product-add-about-title {
        margin-bottom: 20px;
      }
    }

    .product-add-info-photo {
      margin: 10px 0;

      .card-add-photo-wrap {
        max-width: 250px;
      }
    }
  }

  .product-add-info-tags {
    width: 100%;
    padding: 4px 0 8px 0;

    span {
      margin: 1px;
      padding: 5px;
      border-radius: 5px;
      width: 120px;
    }

    /* colors line */
    .red {
      background-color: $red;
    }
    .pink {
      background-color: $pink;
    }
    .purple {
      background-color: $purple;
    }
    .violet {
      background-color: $purple;
    }
    .primary {
      background-color: $indigo;
    }
    .blue {
      background-color: $blue;
    }
    .sky {
      background-color: $sky;
    }
  }
  .cyan {
    background-color: $cyan;
  }
  .teal {
    background-color: $teal;
  }
  .grass {
    background-color: $lime;
  }
  .green {
    background-color: $green;
  }
  .lime {
    background-color: $olive;
  }
  .yellow {
    background-color: $yellow;
  }
  .amber {
    background-color: $amber;
  }
  .orange {
    background-color: $orange;
  }
  .brick {
    background-color: $brick;
  }
  .gray {
    background-color: $gray;
  }
  .dark {
    background-color: $dark;
  }

  .product-add-materials-wrap {
    width: 100%;
    padding: 20px;

    .product-add-materials-title {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media all and (max-width: $ipad) {
  .storages-page {
    padding: 20px 0;

    .storages-info {
      grid-template-columns: 1fr;
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
