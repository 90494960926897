@import "./colors.scss";
@import "./fonts.scss";
@import "./media.scss";

* {
  box-sizing: border-box;
  /*-webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;*/
}

html {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  background-color: $ui-bg-white;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.22;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
header,
footer,
nav,
section {
  display: flex;
  flex-direction: column;
}

/* UI BASE ELEMENTS */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: "Inter";
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    border-style: outset;
    border-width: 0;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-any-link:active {
    color: transparent;
  }
}

button {
  display: flex;
  cursor: pointer;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  font-family: "Inter";
  -webkit-tap-highlight-color: transparent;

  &:active {
    border-style: outset;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-any-link:active {
    color: transparent;
  }
}

input,
textarea {
  padding: 0;
  border: {
    color: transparent;
    width: 0;
  }
  background-color: transparent;
  font-family: "Inter";
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */

  &:focus {
    outline: none;
  }
}

/* MAIN CONTAINERS */

#root {
  width: 100%;
  height: 100vh;
}

#page {
  width: 100%;
  background-color: $ui-bg-light;
}

.page-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;

  .page-search {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
  }
}

.ui-grid {
  display: grid;
}

@media all and (max-width: $ipad) {
  .page-content {
    .page-search {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      margin-top: 4px;
      margin-bottom: 12px;
    }
  }
}
