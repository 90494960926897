@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.dashboard-page {
  flex: 1 1;
  padding: 20px 0;

  .dashboard-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

@media all and (max-width: $ipad) {
  .dashboard-page {
    padding: 20px 0;

    .dashboard-info {
      grid-template-columns: 1fr;
    }

    &.dashboard {
      .table-adaptive {
        .table-card {
          .table-card-col:nth-child(4) {
            display: flex;
            width: 20px;
            height: 100%;
            overflow: hidden;
            align-items: center;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
